import api from "../../utils/api";
import {
  STATISTICS_LOADED,
  STATISTICS_FAIL,
  DETAIL_FAIL,
  DETAIL_LOADED,
} from "../types";

export const loadStatistics = () => async (dispatch: any) => {
  try {
    const res = await api.get("/config/users");

    dispatch({
      type: STATISTICS_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: STATISTICS_FAIL,
    });
  }
};

export const loadDetails = () => async (dispatch: any) => {
  try {
    const res = await api.get("/config/users/details");

    dispatch({
      type: DETAIL_LOADED,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: DETAIL_FAIL,
    });
  }
};
