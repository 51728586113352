export const LOGOUT = "LOGOUT";
export const USER_LOADED = "USER_LOADED";
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const AIRDROP_SUCCESS = "AIRDROP_SUCCESS";
export const AIRDROP_LOADED = "AIRDROP_LOADED";
export const AIRDROP_ERROR = "AIRDROP_ERROR";
export const TASKS_SUCCESS = "TASKS_SUCCESS";
export const TASKS_LOADED = "TASKS_LOADED";
export const TASK_ERROR = "TASK_ERROR";
export const MASKOTS_SUCCESS = "MASKOTS_SUCCESS";
export const MASKOTS_LOADED = "MASKOTS_LOADED";
export const MASKOTS_FAIL = "MASKOTS_FAIL";
export const SKINS_SUCCESS = "SKINS_SUCCESS";
export const SKINS_LOADED = "SKINS_LOADED";
export const SKINS_FAIL = "SKINS_FAIL";
export const MINES_SUCCESS = "MINES_SUCCESS";
export const MINES_LOADED = "MINES_LOADED";
export const MINES_FAIL = "MINES_FAIL";
export const DAILY_SUCCESS = "DAILY_SUCCESS";
export const DAILY_LOADED = "DAILY_LOADED";
export const DAILY_FAIL = "DAILY_FAIL";
export const STATISTICS_LOADED = "STATISTICS_LOADED";
export const STATISTICS_SUCCESS = "STATISTICS_SUCCESS";
export const STATISTICS_FAIL = "STATISTICS_FAIL";
export const DETAIL_LOADED = "DETAIL_LOADED";
export const DETAIL_FAIL = "DETAIL_FAIL";
