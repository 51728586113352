import api from "../../utils/api";
import { AIRDROP_ERROR, AIRDROP_LOADED, AIRDROP_SUCCESS } from "../types";
import { setAlert } from "./alert";

// Load User
export const loadAirdropConfig = () => async (dispatch: any) => {
  try {
    const res = await api.get("/config/airdrop");

    dispatch({
      type: AIRDROP_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AIRDROP_ERROR,
    });
  }
};

// Create / Edit Airdrop Config
export const createAirdropConfig =
  (endDate: string, amount: number, ppf: number, ppt: number, ppb: number) =>
  async (dispatch: any) => {
    const body = { endDate, amount, ppf, ppt, ppb };

    try {
      await api.post("/config/airdrop", body);

      dispatch({
        type: AIRDROP_SUCCESS,
      });

      dispatch(loadAirdropConfig());

      setAlert("Updated Airdrop Configuration", "success");

      return true;
    } catch (err: any) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error: any) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: AIRDROP_ERROR,
      });

      return false;
    }
  };

export const shareTokens = () => (dispatch: any) => {
  try {
    api.get("/config/airdrop/share-tokens");
  } catch (error) {
    dispatch({
      type: AIRDROP_ERROR,
    });
  }
};
