import React, { Fragment, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { loadStatistics } from "../../redux/actions/user";
import Breadcrumb from "../common/BreadCrumb";

interface ITop10Balance {
  auth: IAuth;
  loadStatistics: any;
  statistics: any;
}

interface IAuth {
  isAuthenticated: boolean;
}

const UpSvg = (
  <svg
    className="fill-meta-5"
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.35716 2.47737L0.908974 5.82987L5.0443e-07 4.94612L5 0.0848689L10 4.94612L9.09103 5.82987L5.64284 2.47737L5.64284 10.0849L4.35716 10.0849L4.35716 2.47737Z"
      fill=""
    />
  </svg>
);

const DownSvg = (
  <svg
    className="fill-meta-5"
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.64284 7.69237L9.09102 4.33987L10 5.22362L5 10.0849L-8.98488e-07 5.22362L0.908973 4.33987L4.35716 7.69237L4.35716 0.0848701L5.64284 0.0848704L5.64284 7.69237Z"
      fill=""
    />
  </svg>
);

const Top10Balance: any = ({
  auth: { isAuthenticated },
  loadStatistics,
  statistics,
}: ITop10Balance) => {
  const [sRank, setSortOfRank] = useState<"up" | "down">("down");
  const [sUsername, setSortOfUsername] = useState<"up" | "down">("down");
  const [sBalance, setSortOfBalance] = useState<"up" | "down">("down");
  const [sLevel, setSortOfLevel] = useState<"up" | "down">("down");
  const [sPPH, setSortOfPPH] = useState<"up" | "down">("down");
  const [sCreated, setSortOfCreated] = useState<"up" | "down">("down");
  const [keyword, setKeyword] = useState<string>("");
  const [content, setContent] = useState<any>(null);

  useEffect(() => {
    if (content && content.length > 0) {
      // Sort By Rank
      const temp = content.slice().reverse();
      setContent(temp);
    }
  }, [sRank, sBalance]);

  useEffect(() => {
    if (content && content.length > 0) {
      // Sort By Rank
      if (sUsername === "up") {
        const temp = content.sort((a: any, b: any) => {
          if (a.user_id > b.user_id) {
            return -1;
          }
          if (a.user_id < b.user_id) {
            return 1;
          }
          return 0;
        });
        setContent(temp);
      } else {
        const temp = content.sort((a: any, b: any) => {
          if (a.user_id < b.user_id) {
            return -1;
          }
          if (a.user_id > b.user_id) {
            return 1;
          }
          return 0;
        });
        setContent(temp);
      }
    }
  }, [sUsername]);

  useEffect(() => {
    if (content && content.length > 0) {
      // Sort By Rank
      if (sLevel === "up") {
        const temp = content.sort((a: any, b: any) => {
          if (a.level_id > b.level_id) {
            return -1;
          }
          if (a.level_id < b.level_id) {
            return 1;
          }
          return 0;
        });
        setContent(temp);
      } else {
        const temp = content.sort((a: any, b: any) => {
          if (a.level_id < b.level_id) {
            return -1;
          }
          if (a.level_id > b.level_id) {
            return 1;
          }
          return 0;
        });
        setContent(temp);
      }
    }
  }, [sLevel]);

  useEffect(() => {
    if (content && content.length > 0) {
      // Sort By Rank
      if (sPPH === "up") {
        const temp = content.sort((a: any, b: any) => {
          if (a.rewardPerHour > b.rewardPerHour) {
            return -1;
          }
          if (a.rewardPerHour < b.rewardPerHour) {
            return 1;
          }
          return 0;
        });
        setContent(temp);
      } else {
        const temp = content.sort((a: any, b: any) => {
          if (a.rewardPerHour < b.rewardPerHour) {
            return -1;
          }
          if (a.rewardPerHour > b.rewardPerHour) {
            return 1;
          }
          return 0;
        });
        setContent(temp);
      }
    }
  }, [sPPH]);

  useEffect(() => {
    if (content && content.length > 0) {
      // Sort By Rank
      if (sCreated === "up") {
        const temp = content.sort((a: any, b: any) => {
          if (a.created_at > b.created_at) {
            return -1;
          }
          if (a.created_at < b.created_at) {
            return 1;
          }
          return 0;
        });
        setContent(temp);
      } else {
        const temp = content.sort((a: any, b: any) => {
          if (a.created_at < b.created_at) {
            return -1;
          }
          if (a.created_at > b.created_at) {
            return 1;
          }
          return 0;
        });
        setContent(temp);
      }
    }
  }, [sCreated]);

  useEffect(() => {
    if (
      statistics &&
      statistics.TopTenHighBalanceUsers &&
      statistics.TopTenHighBalanceUsers.length > 0
    ) {
      const temp = statistics.TopTenHighBalanceUsers.filter(
        (item: any) =>
          String(item.user_id).includes(keyword) ||
          String(item.balance).includes(keyword) ||
          String(item.level_id).includes(keyword) ||
          String(item.rewardPerHour).includes(keyword) ||
          String(item.created_at).includes(keyword)
      );
      setContent(temp);
    }
  }, [keyword]);

  useEffect(() => {
    if (
      statistics &&
      statistics.TopTenHighBalanceUsers &&
      statistics.TopTenHighBalanceUsers.length > 0
    ) {
      setContent(statistics.TopTenHighBalanceUsers);
    }
  }, [statistics]);

  useEffect(() => {
    loadStatistics();
  }, [loadStatistics]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  let playerContent;
  if (content && content.length > 0)
    playerContent = content.map((_: any, idx: number) => (
      <tr key={_._id}>
        <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
          {sRank === "down" ? idx + 1 : content.length - idx}
        </td>
        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
          <p className="text-black dark:text-white">{_.user_id}</p>
        </td>
        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
          <p className="text-black dark:text-white">
            {Number(_.balance).toFixed(0)}
          </p>
        </td>
        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
          <p className="text-black dark:text-white">{_.level_id}</p>
        </td>
        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
          <p className="text-black dark:text-white">{_.rewardPerHour}</p>
        </td>
        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
          <p className="text-black dark:text-white">
            {new Intl.DateTimeFormat("tr-TR", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }).format(new Date(_.created_at).getTime())}
          </p>
        </td>
      </tr>
    ));

  return (
    <Fragment>
      <>
        <Breadcrumb pageName="Top 10 Users (Balance)" />
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <input
            type="text"
            placeholder="Enter the keyword"
            className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary max-w-[300px] mb-2"
            name="keyword"
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
          />
          <div className="max-w-full overflow-x-auto">
            {content && content.length > 0 ? (
              <table className="w-full table-auto" style={{ marginTop: 5 }}>
                <thead>
                  <tr className="bg-gray-2 text-left dark:bg-meta-4">
                    <th
                      className="py-4 px-4 font-medium text-black dark:text-white xl:pl-11 cursor-pointer"
                      onClick={() =>
                        setSortOfRank(sRank === "down" ? "up" : "down")
                      }
                    >
                      <span className="flex items-center gap-1 text-sm font-medium">
                        Rank
                        {sRank === "up" ? UpSvg : DownSvg}
                      </span>
                    </th>
                    <th
                      className="py-4 px-4 font-medium text-black dark:text-white cursor-pointer"
                      onClick={() =>
                        setSortOfUsername(sUsername === "down" ? "up" : "down")
                      }
                    >
                      <span className="flex items-center gap-1 text-sm font-medium">
                        Username
                        {sUsername === "up" ? UpSvg : DownSvg}
                      </span>
                    </th>
                    <th
                      className="py-4 px-4 font-medium text-black dark:text-white cursor-pointer"
                      onClick={() =>
                        setSortOfBalance(sBalance === "down" ? "up" : "down")
                      }
                    >
                      <span className="flex items-center gap-1 text-sm font-medium">
                        Balance
                        {sBalance === "up" ? UpSvg : DownSvg}
                      </span>
                    </th>
                    <th
                      className="py-4 px-4 font-medium text-black dark:text-white cursor-pointer"
                      onClick={() =>
                        setSortOfLevel(sLevel === "down" ? "up" : "down")
                      }
                    >
                      <span className="flex items-center gap-1 text-sm font-medium">
                        Level
                        {sLevel === "up" ? UpSvg : DownSvg}
                      </span>
                    </th>
                    <th
                      className="py-4 px-4 font-medium text-black dark:text-white cursor-pointer"
                      onClick={() =>
                        setSortOfPPH(sPPH === "down" ? "up" : "down")
                      }
                    >
                      <span className="flex items-center gap-1 text-sm font-medium">
                        Profit Per Hour
                        {sPPH === "up" ? UpSvg : DownSvg}
                      </span>
                    </th>
                    <th
                      className="py-4 px-4 font-medium text-black dark:text-white cursor-pointer"
                      onClick={() =>
                        setSortOfCreated(sCreated === "down" ? "up" : "down")
                      }
                    >
                      <span className="flex items-center gap-1 text-sm font-medium">
                        Created At
                        {sCreated === "up" ? UpSvg : DownSvg}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>{playerContent}</tbody>
              </table>
            ) : (
              <p className="text-center mb-3">No Users</p>
            )}
          </div>
        </div>
      </>
    </Fragment>
  );
};

Top10Balance.propTypes = {
  loadStatistics: PropTypes.func.isRequired,
  statistics: PropTypes.object,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  statistics: state.statistics.statistics,
});

export default connect(mapStateToProps, { loadStatistics })(Top10Balance);
