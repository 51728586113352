import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createTask } from "../../redux/actions/task";
import Breadcrumb from "../common/BreadCrumb";

interface ICreateTask {
  createTask: any;
  isAuthenticated: boolean;
}

const AddTask: any = ({ createTask, isAuthenticated }: ICreateTask) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    type: "",
    description: "",
    price: 0,
    link: "",
  });

  const { title, type, description, price, link } = formData;

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    createTask(formData).then(() => navigate("/tasks"));
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Breadcrumb pageName="Create a Task" />

      <div className="w-full p-4 sm:p-12.5 xl:p-10.5">
        <div className="flex flex-col gap-9">
          {/* <!-- Contact Form --> */}
          <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
            <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
              <h3 className="font-medium text-black dark:text-white">
                Configuration Form
              </h3>
            </div>
            <form className="form" onSubmit={onSubmit}>
              <div className="form-group">
                <div className="p-6.5">
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Title
                    </label>
                    <input
                      type="text"
                      placeholder="Enter the title"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="title"
                      value={title}
                      onChange={onChange}
                    />
                    <p>The title of the task.</p>
                  </div>
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Title
                    </label>
                    <input
                      type="text"
                      placeholder="Enter the type"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="type"
                      value={type}
                      onChange={onChange}
                    />
                    <p>
                      The type of the task. Here are two types and you must set
                      this with one among them. They are blog and social type
                      names.
                    </p>
                  </div>
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Initial Price
                    </label>
                    <input
                      type="number"
                      placeholder="Enter the price"
                      min={0}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="price"
                      value={price}
                      onChange={onChange}
                    />
                    <p>The bonus amount when you completed this task.</p>
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Title
                    </label>
                    <input
                      type="text"
                      placeholder="Enter the link"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="link"
                      value={link}
                      onChange={onChange}
                    />
                    <p>The link of the task blog or social.</p>
                  </div>
                  <div className="mb-4.5">
                    <label className="mb-3 block text-black dark:text-white">
                      Description
                    </label>
                    <textarea
                      rows={6}
                      placeholder="Enter the description"
                      className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="description"
                      value={description}
                      onChange={onChange}
                      minLength={6}
                    ></textarea>
                  </div>
                  <input
                    type="submit"
                    className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
                    value="Submit"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

AddTask.propTypes = {
  createTask: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { createTask })(AddTask);
