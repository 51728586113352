import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createSkin } from "../../redux/actions/skin";
import { setAlert } from "../../redux/actions/alert";
import Breadcrumb from "../common/BreadCrumb";

interface ICreateSkin {
  createSkin: any;
  isAuthenticated: boolean;
}

const AddSkin: any = ({ createSkin, isAuthenticated }: ICreateSkin) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: 0,
  });
  const [file, setFile] = useState<File | null>(null);

  const { title, description, price } = formData;

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e.target.files;
    if (!files || files?.length === 0) return;

    setFile(files[0]);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (!file) {
      setAlert("File is not uploaded", "danger");
      return;
    }

    const formData_ = new FormData();
    formData_.append("title", title);
    formData_.append("price", String(price));
    formData_.append("description", description);
    formData_.append("file", file);

    createSkin(formData_).then(() => navigate("/skins"));
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Breadcrumb pageName="Create a Skin" />

      <div>
        <div className="w-full p-4 sm:p-12.5 xl:p-10.5">
          <div className="flex flex-col gap-9">
            {/* <!-- Contact Form --> */}
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  Configuration Form
                </h3>
              </div>
              <form className="form" onSubmit={onSubmit}>
                <div className="p-6.5">
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Title
                    </label>
                    <input
                      type="text"
                      placeholder="Enter the title"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="title"
                      value={title}
                      onChange={onChange}
                    />
                    <p>The title of the skin item.</p>
                  </div>
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Price
                    </label>
                    <input
                      type="number"
                      placeholder="Enter the price"
                      min={0}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="price"
                      value={price}
                      onChange={onChange}
                    />
                    <p>The price of this skin.</p>
                  </div>
                  <div className="mb-4.5">
                    <label className="mb-3 block text-black dark:text-white">
                      Description
                    </label>
                    <textarea
                      rows={6}
                      placeholder="Enter the description"
                      className="w-full rounded-lg border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="description"
                      value={description}
                      onChange={onChange}
                      minLength={6}
                    ></textarea>
                  </div>
                  <div className="mb-4.5">
                    <label className="mb-3 block text-black dark:text-white">
                      Attach file
                    </label>
                    <input
                      type="file"
                      name="file"
                      onChange={handleFileUpload}
                      className="w-full rounded-md border border-stroke p-3 outline-none transition file:mr-4 file:rounded file:border-[0.5px] file:border-stroke file:bg-[#EEEEEE] file:py-1 file:px-2.5 file:text-sm focus:border-primary file:focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-strokedark dark:file:bg-white/30 dark:file:text-white"
                    />
                  </div>
                  <input
                    type="submit"
                    className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddSkin.propTypes = {
  createSkin: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { createSkin })(AddSkin);
