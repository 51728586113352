import { DETAIL_LOADED, STATISTICS_LOADED } from "../types";

const initialState = {
  loading: true,
  statistics: null,
  details: null,
};

function statisticsReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case STATISTICS_LOADED:
      return {
        ...state,
        loading: false,
        statistics: payload,
      };
    case DETAIL_LOADED:
      return {
        ...state,
        loading: false,
        details: payload,
      };
    default:
      return state;
  }
}

export default statisticsReducer;
