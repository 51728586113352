import api from "../../utils/api";

import { DAILY_FAIL, DAILY_LOADED, DAILY_SUCCESS } from "../types";
import { setAlert } from "./alert";

export const loadDailyTask = () => async (dispatch: any) => {
  try {
    const res = await api.get("/config/daily");

    dispatch({
      type: DAILY_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: DAILY_FAIL,
    });
  }
};

export const createDaily = (formData: any) => async (dispatch: any) => {
  try {
    await api.post("/config/daily", formData);

    dispatch({
      type: DAILY_SUCCESS,
    });

    dispatch(loadDailyTask());

    setAlert("Daily task updated", "success");

    return true;
  } catch (err: any) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error: any) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: DAILY_FAIL,
    });

    return false;
  }
};
