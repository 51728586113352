import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { loadDailyTask, createDaily } from "../../redux/actions/daily";
import { loadSkins } from "../../redux/actions/skin";
import { loadMines } from "../../redux/actions/mine";
import Breadcrumb from "../common/BreadCrumb";
import flatpickr from "flatpickr";

interface IDaily {
  daily_task: IDailyTask;
  loading: boolean;
}

interface IDailyTask {
  date: string;
  type: string;
  skin_id: string;
  amount: number;
  cipher: number;
  cipher_bonus: number;
  combo: string;
  combo_bonus: number;
}

const initialState = {
  date: "",
  type: "",
  skin_id: "",
  amount: 0,
  cipher: 0,
  cipher_bonus: 0,
  combo: "",
  combo_bonus: 0,
};

const DailyTask: any = ({
  isAuthenticated,
  daily: { daily_task, loading },
  createDaily,
  loadDailyTask,
  loadSkins,
  skins,
  loadMines,
  mines,
}: {
  isAuthenticated: boolean;
  createDaily: any;
  loadDailyTask: any;
  daily: IDaily;
  loadSkins: any;
  loadMines: any;
  skins: any;
  mines: any;
}) => {
  const [formData, setFormData] = useState(initialState);
  const [isOptionSelected, setIsOptionSelected] = useState<boolean>(false);

  const navigate = useNavigate();

  const {
    date,
    type,
    skin_id,
    amount,
    cipher,
    cipher_bonus,
    combo,
    combo_bonus,
  } = formData;

  useEffect(() => {
    // Init flatpickr
    flatpickr(".form-datepicker", {
      mode: "single",
      onChange: function (selectedDates, dateStr, instance) {
        setFormData({ ...formData, ["date"]: String(selectedDates[0]) });
      },
      defaultDate: new Date(date),
      static: true,
      monthSelectorType: "static",
      enableTime: true,
      dateFormat: "M j, Y",
      prevArrow:
        '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    });
  }, [date]);

  useEffect(() => {
    if (!daily_task) loadDailyTask();
    if (!skins) loadSkins();
    if (!mines) loadMines();

    if (!loading && daily_task) {
      const configData = { ...initialState };

      const formattedEndDate = new Date(daily_task.date)
        .toISOString()
        .slice(0, 10);

      configData.date = formattedEndDate;
      configData.type = daily_task.type;
      configData.skin_id = daily_task.skin_id;
      configData.cipher = daily_task.cipher;
      configData.combo = daily_task.combo;
      configData.amount = daily_task.amount;
      configData.cipher_bonus = daily_task.cipher_bonus;
      configData.combo_bonus = daily_task.combo_bonus;

      setFormData(configData);
    }
  }, [loading, daily_task, loadDailyTask, mines, skins, loadSkins, loadMines]);

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    changeTextColor();
  };

  const changeTextColor = () => {
    setIsOptionSelected(true);
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    const formData = {
      date,
      type,
      skin_id,
      amount,
      cipher,
      cipher_bonus,
      combo,
      combo_bonus,
    };

    createDaily(formData).then((res: boolean) => {
      if (res) navigate("/dashboard");
    });
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  let mineSelect;
  if (mines && mines.length > 0)
    mineSelect = mines.map((mine: any, idx: number) => (
      <option
        key={idx}
        value={mine._id}
        className="text-body dark:text-bodydark"
      >
        {mine.title}
      </option>
    ));

  let skinSelect;
  if (skins && skins.length > 0)
    skinSelect = skins.map((skin: any, idx: number) => (
      <option
        key={idx}
        value={skin._id}
        className="text-body dark:text-bodydark"
      >
        {skin.title}
      </option>
    ));

  return (
    <>
      <Breadcrumb pageName="Daily Task Configuration" />
      <div>
        <div className="w-full p-4 sm:p-12.5 xl:p-10.5">
          <div className="flex flex-col gap-9">
            {/* <!-- Contact Form --> */}
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  Configuration Form
                </h3>
              </div>
              <form className="form" onSubmit={onSubmit}>
                <div className="p-6.5">
                  <div className="mb-4.5 flex flex-col gap-6 xl:flex-row">
                    <div className="w-full xl:w-1/2">
                      <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                        Date (to sets up daily tasks)
                      </label>
                      <div className="relative">
                        <input
                          type="date"
                          name="date"
                          onChange={onChange}
                          className="form-datepicker w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                          placeholder="mm/dd/yyyy"
                          data-class="flatpickr-right"
                        />

                        <div className="pointer-events-none absolute inset-0 left-auto right-5 flex items-center">
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M15.7504 2.9812H14.2879V2.36245C14.2879 2.02495 14.0066 1.71558 13.641 1.71558C13.2754 1.71558 12.9941 1.99683 12.9941 2.36245V2.9812H4.97852V2.36245C4.97852 2.02495 4.69727 1.71558 4.33164 1.71558C3.96602 1.71558 3.68477 1.99683 3.68477 2.36245V2.9812H2.25039C1.29414 2.9812 0.478516 3.7687 0.478516 4.75308V14.5406C0.478516 15.4968 1.26602 16.3125 2.25039 16.3125H15.7504C16.7066 16.3125 17.5223 15.525 17.5223 14.5406V4.72495C17.5223 3.7687 16.7066 2.9812 15.7504 2.9812ZM1.77227 8.21245H4.16289V10.9968H1.77227V8.21245ZM5.42852 8.21245H8.38164V10.9968H5.42852V8.21245ZM8.38164 12.2625V15.0187H5.42852V12.2625H8.38164V12.2625ZM9.64727 12.2625H12.6004V15.0187H9.64727V12.2625ZM9.64727 10.9968V8.21245H12.6004V10.9968H9.64727ZM13.8379 8.21245H16.2285V10.9968H13.8379V8.21245ZM2.25039 4.24683H3.71289V4.83745C3.71289 5.17495 3.99414 5.48433 4.35977 5.48433C4.72539 5.48433 5.00664 5.20308 5.00664 4.83745V4.24683H13.0504V4.83745C13.0504 5.17495 13.3316 5.48433 13.6973 5.48433C14.0629 5.48433 14.3441 5.20308 14.3441 4.83745V4.24683H15.7504C16.0316 4.24683 16.2566 4.47183 16.2566 4.75308V6.94683H1.77227V4.75308C1.77227 4.47183 1.96914 4.24683 2.25039 4.24683ZM1.77227 14.5125V12.2343H4.16289V14.9906H2.25039C1.96914 15.0187 1.77227 14.7937 1.77227 14.5125ZM15.7504 15.0187H13.8379V12.2625H16.2285V14.5406C16.2566 14.7937 16.0316 15.0187 15.7504 15.0187Z"
                              fill="#64748B"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>

                    <div className="w-full xl:w-1/2">
                      <label className="mb-2.5 block text-black dark:text-white">
                        Type
                      </label>
                      <input
                        type="text"
                        placeholder="Enter your task type"
                        className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                        name="type"
                        value={type}
                        onChange={onChange}
                      />
                      <p>
                        Two types: skin and prize. You must set up this field
                        with one among those two values
                      </p>
                    </div>
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      {" "}
                      Skin{" "}
                    </label>

                    <div className="relative z-20 bg-transparent dark:bg-form-input">
                      <select
                        name="skin_id"
                        value={skin_id}
                        onChange={onChange}
                        className={`relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary ${
                          isOptionSelected ? "text-black dark:text-white" : ""
                        }`}
                      >
                        <option
                          value=""
                          disabled
                          className="text-body dark:text-bodydark"
                        >
                          Select your skin
                        </option>
                        {skinSelect}
                      </select>

                      <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                        <svg
                          className="fill-current"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                              fill=""
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </div>
                    <p>Select one skin to give users as daily bonus</p>
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Prize Amount
                    </label>
                    <input
                      type="number"
                      placeholder="Enter your prize amount"
                      min={0}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="amount"
                      value={amount}
                      onChange={onChange}
                    />
                    <p>
                      Prize amount to give users; this value is used when daily
                      task type is prize.
                    </p>
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Cipher Code
                    </label>
                    <input
                      type="number"
                      placeholder="Please enter cipher code"
                      min={0}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="cipher"
                      value={cipher}
                      onChange={onChange}
                      maxLength={5}
                    />
                    <p>
                      This is a 5 digits Cipher code for cipher task. Please
                      enter 5 digits
                    </p>
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Cipher Bonus
                    </label>
                    <input
                      type="number"
                      placeholder="Please enter cipher bonus"
                      min={0}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="cipher_bonus"
                      value={cipher_bonus}
                      onChange={onChange}
                    />
                    <p>
                      This is Cipher Bonus; users can get this bonus when they
                      guessed the cipher code correctly
                    </p>
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      {" "}
                      Skin{" "}
                    </label>

                    <div className="relative z-20 bg-transparent dark:bg-form-input">
                      <select
                        name="combo"
                        value={combo}
                        onChange={onChange}
                        className={`relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-5 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary ${
                          isOptionSelected ? "text-black dark:text-white" : ""
                        }`}
                      >
                        <option
                          value=""
                          disabled
                          className="text-body dark:text-bodydark"
                        >
                          Select your mine item
                        </option>
                        {mineSelect}
                      </select>

                      <span className="absolute top-1/2 right-4 z-30 -translate-y-1/2">
                        <svg
                          className="fill-current"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                              fill=""
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </div>
                    <p>Select one mine item for daily combo task</p>
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Combo Bonus
                    </label>
                    <input
                      type="number"
                      placeholder="Please enter combo bonus"
                      min={0}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="combo_bonus"
                      value={combo_bonus}
                      onChange={onChange}
                    />
                    <p>
                      This is Combo Bonus; users can get this bonus when they
                      guessed the mine item correctly
                    </p>
                  </div>

                  <input
                    type="submit"
                    className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
                    value={"Submit"}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

DailyTask.propTypes = {
  createDaily: PropTypes.func.isRequired,
  loadDailyTask: PropTypes.func.isRequired,
  loadMines: PropTypes.func.isRequired,
  loadSkins: PropTypes.func.isRequired,
  daily: PropTypes.object.isRequired,
  skins: PropTypes.array,
  mines: PropTypes.array,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  daily: state.daily,
  skins: state.skin.skins,
  mines: state.mine.mines,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  loadDailyTask,
  loadMines,
  loadSkins,
  createDaily,
})(DailyTask);
