import api from "../../utils/api";
import { MASKOTS_FAIL, MASKOTS_LOADED, MASKOTS_SUCCESS } from "../types";
import { setAlert } from "./alert";

export const loadMaskots = () => async (dispatch: any) => {
  try {
    const res = await api.get("/config/maskots");

    dispatch({
      type: MASKOTS_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MASKOTS_FAIL,
    });
  }
};

export const createMaskot = (formData: any) => async (dispatch: any) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    await api.post("/config/maskots", formData, config);

    dispatch({
      type: MASKOTS_SUCCESS,
    });

    dispatch(loadMaskots());

    setAlert("Maskot created", "success");

    return true;
  } catch (err: any) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error: any) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: MASKOTS_FAIL,
    });

    return false;
  }
};

export const deleteMaskot = (id: string) => async (dispatch: any) => {
  try {
    await api.delete(`/config/maskots/${id}`);

    dispatch({
      type: MASKOTS_SUCCESS,
    });

    dispatch(loadMaskots());
    setAlert("Maskot deleted", "success");
  } catch (error) {
    setAlert("Delete a maskot failed", "danger");

    dispatch({
      type: MASKOTS_FAIL,
    });
  }
};
