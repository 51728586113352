import { MASKOTS_LOADED } from "../types";

const initialState = {
  loading: true,
  maskots: null,
};

function maskotReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case MASKOTS_LOADED:
      return {
        ...state,
        loading: false,
        maskots: payload,
      };
    default:
      return state;
  }
}

export default maskotReducer;
