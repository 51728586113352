import { AIRDROP_LOADED } from "../types";

const initialState = {
  loading: true,
  config: null,
};

function airdropReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case AIRDROP_LOADED:
      return {
        ...state,
        loading: false,
        config: payload,
      };
    default:
      return state;
  }
}

export default airdropReducer;
