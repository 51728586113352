import { combineReducers } from "redux";
import auth from "./auth";
import airdrop from "./airdrop";
import task from "./task";
import maskot from "./maskot";
import skin from "./skin";
import mine from "./mine";
import daily from "./daily";
import alert from "./alert";
import statistics from "./user";

export default combineReducers({
  auth,
  airdrop,
  task,
  maskot,
  skin,
  mine,
  daily,
  alert,
  statistics,
});
