import api from "../../utils/api";
import { TASKS_LOADED, TASKS_SUCCESS, TASK_ERROR } from "../types";
import { setAlert } from "./alert";

export const loadTasks = () => async (dispatch: any) => {
  try {
    const res = await api.get("/config/tasks");

    dispatch({
      type: TASKS_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: TASK_ERROR,
    });
  }
};

export const createTask = (formData: any) => async (dispatch: any) => {
  try {
    await api.post("/config/tasks", formData);

    dispatch({
      type: TASKS_SUCCESS,
    });

    dispatch(loadTasks());

    setAlert("Task created", "success");

    return true;
  } catch (err: any) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error: any) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: TASK_ERROR,
    });

    return false;
  }
};

export const deleteTask = (id: string) => async (dispatch: any) => {
  try {
    await api.delete(`/config/tasks/${id}`);

    dispatch({
      type: TASKS_SUCCESS,
    });

    dispatch(loadTasks());
    setAlert("Task deleted", "success");
  } catch (error) {
    setAlert("Delete a task failed", "danger");

    dispatch({
      type: TASK_ERROR,
    });
  }
};
