import { SKINS_LOADED } from "../types";

const initialState = {
  loading: true,
  skins: null,
};

function skinReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SKINS_LOADED:
      return {
        ...state,
        loading: false,
        skins: payload,
      };
    default:
      return state;
  }
}

export default skinReducer;
