import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Breadcrumb from "../common/BreadCrumb";
import CardDataStats from "../common/CardDataStats";
import { loadStatistics } from "../../redux/actions/user";
import { formatNumber } from "../../utils/formatNumber";

interface IDashboard {
  auth: IAuth;
  loadStatistics: any;
  statistics: any;
}

interface IAuth {
  isAuthenticated: boolean;
}

const UserIcon = (
  <svg
    className="fill-primary dark:fill-white"
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.18418 8.03751C9.31543 8.03751 11.0686 6.35313 11.0686 4.25626C11.0686 2.15938 9.31543 0.475006 7.18418 0.475006C5.05293 0.475006 3.2998 2.15938 3.2998 4.25626C3.2998 6.35313 5.05293 8.03751 7.18418 8.03751ZM7.18418 2.05626C8.45605 2.05626 9.52168 3.05313 9.52168 4.29063C9.52168 5.52813 8.49043 6.52501 7.18418 6.52501C5.87793 6.52501 4.84668 5.52813 4.84668 4.29063C4.84668 3.05313 5.9123 2.05626 7.18418 2.05626Z"
      fill=""
    />
    <path
      d="M15.8124 9.6875C17.6687 9.6875 19.1468 8.24375 19.1468 6.42188C19.1468 4.6 17.6343 3.15625 15.8124 3.15625C13.9905 3.15625 12.478 4.6 12.478 6.42188C12.478 8.24375 13.9905 9.6875 15.8124 9.6875ZM15.8124 4.7375C16.8093 4.7375 17.5999 5.49375 17.5999 6.45625C17.5999 7.41875 16.8093 8.175 15.8124 8.175C14.8155 8.175 14.0249 7.41875 14.0249 6.45625C14.0249 5.49375 14.8155 4.7375 15.8124 4.7375Z"
      fill=""
    />
    <path
      d="M15.9843 10.0313H15.6749C14.6437 10.0313 13.6468 10.3406 12.7874 10.8563C11.8593 9.61876 10.3812 8.79376 8.73115 8.79376H5.67178C2.85303 8.82814 0.618652 11.0625 0.618652 13.8469V16.3219C0.618652 16.975 1.13428 17.4906 1.7874 17.4906H20.2468C20.8999 17.4906 21.4499 16.9406 21.4499 16.2875V15.4625C21.4155 12.4719 18.9749 10.0313 15.9843 10.0313ZM2.16553 15.9438V13.8469C2.16553 11.9219 3.74678 10.3406 5.67178 10.3406H8.73115C10.6562 10.3406 12.2374 11.9219 12.2374 13.8469V15.9438H2.16553V15.9438ZM19.8687 15.9438H13.7499V13.8469C13.7499 13.2969 13.6468 12.7469 13.4749 12.2313C14.0937 11.7844 14.8499 11.5781 15.6405 11.5781H15.9499C18.0812 11.5781 19.8343 13.3313 19.8343 15.4625V15.9438H19.8687Z"
      fill=""
    />
  </svg>
);

const gameIcon = (
  <svg
    className="fill-primary dark:fill-white"
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.7531 16.4312C10.3781 16.4312 9.27808 17.5312 9.27808 18.9062C9.27808 20.2812 10.3781 21.3812 11.7531 21.3812C13.1281 21.3812 14.2281 20.2812 14.2281 18.9062C14.2281 17.5656 13.0937 16.4312 11.7531 16.4312ZM11.7531 19.8687C11.2375 19.8687 10.825 19.4562 10.825 18.9406C10.825 18.425 11.2375 18.0125 11.7531 18.0125C12.2687 18.0125 12.6812 18.425 12.6812 18.9406C12.6812 19.4219 12.2343 19.8687 11.7531 19.8687Z"
      fill=""
    />
    <path
      d="M5.22183 16.4312C3.84683 16.4312 2.74683 17.5312 2.74683 18.9062C2.74683 20.2812 3.84683 21.3812 5.22183 21.3812C6.59683 21.3812 7.69683 20.2812 7.69683 18.9062C7.69683 17.5656 6.56245 16.4312 5.22183 16.4312ZM5.22183 19.8687C4.7062 19.8687 4.2937 19.4562 4.2937 18.9406C4.2937 18.425 4.7062 18.0125 5.22183 18.0125C5.73745 18.0125 6.14995 18.425 6.14995 18.9406C6.14995 19.4219 5.73745 19.8687 5.22183 19.8687Z"
      fill=""
    />
    <path
      d="M19.0062 0.618744H17.15C16.325 0.618744 15.6031 1.23749 15.5 2.06249L14.95 6.01562H1.37185C1.0281 6.01562 0.684353 6.18749 0.443728 6.46249C0.237478 6.73749 0.134353 7.11562 0.237478 7.45937C0.237478 7.49374 0.237478 7.49374 0.237478 7.52812L2.36873 13.9562C2.50623 14.4375 2.9531 14.7812 3.46873 14.7812H12.9562C14.2281 14.7812 15.3281 13.8187 15.5 12.5469L16.9437 2.26874C16.9437 2.19999 17.0125 2.16562 17.0812 2.16562H18.9375C19.35 2.16562 19.7281 1.82187 19.7281 1.37499C19.7281 0.928119 19.4187 0.618744 19.0062 0.618744ZM14.0219 12.3062C13.9531 12.8219 13.5062 13.2 12.9906 13.2H3.7781L1.92185 7.56249H14.7094L14.0219 12.3062Z"
      fill=""
    />
  </svg>
);

const eventIcon = (
  <svg
    className="fill-primary dark:fill-white"
    width="22"
    height="16"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 15.1156C4.19376 15.1156 0.825012 8.61876 0.687512 8.34376C0.584387 8.13751 0.584387 7.86251 0.687512 7.65626C0.825012 7.38126 4.19376 0.918762 11 0.918762C17.8063 0.918762 21.175 7.38126 21.3125 7.65626C21.4156 7.86251 21.4156 8.13751 21.3125 8.34376C21.175 8.61876 17.8063 15.1156 11 15.1156ZM2.26876 8.00001C3.02501 9.27189 5.98126 13.5688 11 13.5688C16.0188 13.5688 18.975 9.27189 19.7313 8.00001C18.975 6.72814 16.0188 2.43126 11 2.43126C5.98126 2.43126 3.02501 6.72814 2.26876 8.00001Z"
      fill=""
    />
    <path
      d="M11 10.9219C9.38438 10.9219 8.07812 9.61562 8.07812 8C8.07812 6.38438 9.38438 5.07812 11 5.07812C12.6156 5.07812 13.9219 6.38438 13.9219 8C13.9219 9.61562 12.6156 10.9219 11 10.9219ZM11 6.625C10.2437 6.625 9.625 7.24375 9.625 8C9.625 8.75625 10.2437 9.375 11 9.375C11.7563 9.375 12.375 8.75625 12.375 8C12.375 7.24375 11.7563 6.625 11 6.625Z"
      fill=""
    />
  </svg>
);

const Dashboard: any = ({
  auth: { isAuthenticated },
  loadStatistics,
  statistics,
}: IDashboard) => {
  useEffect(() => {
    loadStatistics();
  }, [loadStatistics]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <section className="container">
      <Breadcrumb pageName="Summary" />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <CardDataStats
          title="Total users"
          total={
            statistics && statistics.totalUserCnt
              ? String(statistics.totalUserCnt)
              : "0"
          }
        >
          {UserIcon}
        </CardDataStats>
        <CardDataStats
          title="24 hour Active users"
          total={
            statistics && statistics.last24HoursLoggedInUserCnt
              ? String(statistics.last24HoursLoggedInUserCnt)
              : "0"
          }
        >
          {UserIcon}
        </CardDataStats>
        <CardDataStats
          title="1 week Active users"
          total={
            statistics && statistics.lastWeekActiveUserCnt
              ? String(statistics.lastWeekActiveUserCnt)
              : "0"
          }
        >
          {UserIcon}
        </CardDataStats>
        <CardDataStats
          title="1 month Active users"
          total={
            statistics && statistics.lastMonthActiveUserCnt
              ? String(statistics.lastMonthActiveUserCnt)
              : "0"
          }
        >
          {UserIcon}
        </CardDataStats>
        <CardDataStats
          title="1 month Created users"
          total={
            statistics && statistics.lastMonthCreatedUserCnt
              ? String(statistics.lastMonthCreatedUserCnt)
              : "0"
          }
        >
          {UserIcon}
        </CardDataStats>
        <CardDataStats
          title="6 months Created users"
          total={
            statistics && statistics.lastSixMonthsCreatedUserCnt
              ? String(statistics.lastSixMonthsCreatedUserCnt)
              : "0"
          }
        >
          {UserIcon}
        </CardDataStats>
        <CardDataStats
          title="1 year Created users"
          total={
            statistics && statistics.lastOneYearCreatedUserCnt
              ? String(statistics.lastOneYearCreatedUserCnt)
              : "0"
          }
        >
          {UserIcon}
        </CardDataStats>
        <CardDataStats
          title="Total Tapped"
          total={
            statistics && statistics.totalTapped
              ? formatNumber(statistics.totalTapped)
              : "0"
          }
          route="/details"
          rate="More details"
          levelDown
        >
          {gameIcon}
        </CardDataStats>
        <CardDataStats
          title="Total Spent"
          total={
            statistics && statistics.summary && statistics.summary.total_spent
              ? String(statistics.summary.total_spent)
              : "0"
          }
          route="/details"
          rate="More details"
          levelDown
        >
          {gameIcon}
        </CardDataStats>
        <CardDataStats
          title="Total Airdropped"
          total={
            statistics && statistics.summary && statistics.summary.total_mined
              ? String(statistics.summary.total_mined)
              : "0"
          }
          route="/details"
          rate="More details"
          levelDown
        >
          {gameIcon}
        </CardDataStats>
        <CardDataStats
          title="Current Airdrop EndDate"
          total={
            statistics && statistics.airdropEndDate
              ? new Intl.DateTimeFormat("tr-TR", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                }).format(new Date(statistics.airdropEndDate).getTime())
              : "0"
          }
        >
          {eventIcon}
        </CardDataStats>
        <CardDataStats
          title="Airdrop Participants"
          total={
            statistics &&
            statistics.summary &&
            statistics.summary.total_airdropped
              ? String(statistics.summary.total_airdropped)
              : "0"
          }
        >
          {eventIcon}
        </CardDataStats>
        <CardDataStats
          title="Total Referral Bonus"
          total={
            statistics && statistics.summary && statistics.summary.referral
              ? String(statistics.summary.referral)
              : "0"
          }
        >
          {eventIcon}
        </CardDataStats>
        <CardDataStats
          title="Top 10 Players (By balance)"
          total={
            statistics && statistics.summary && statistics.summary.referral
              ? String(statistics.summary.referral)
              : "0"
          }
          route="/top-balances"
          rate="More details"
          levelDown
        >
          {eventIcon}
        </CardDataStats>
        <CardDataStats
          title="Total 10 Players (By level)"
          total={
            statistics && statistics.summary && statistics.summary.referral
              ? String(statistics.summary.referral)
              : "0"
          }
          route="/top-levels"
          rate="More details"
          levelDown
        >
          {eventIcon}
        </CardDataStats>
      </div>
    </section>
  );
};

Dashboard.propTypes = {
  loadStatistics: PropTypes.func.isRequired,
  statistics: PropTypes.object,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
  statistics: state.statistics.statistics,
});

export default connect(mapStateToProps, { loadStatistics })(Dashboard);
