import { DAILY_LOADED } from "../types";

const initialState = {
  loading: true,
  daily_task: null,
};

function dailyReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case DAILY_LOADED:
      return {
        ...state,
        loading: false,
        daily_task: payload,
      };
    default:
      return state;
  }
}

export default dailyReducer;
