import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Load Components
import DefaultLayout from "./components/layouts/DefaultLayout";
import PageTitle from "./components/common/PageTitle";
import Landing from "./components/layouts/Landing";
import Dashboard from "./components/dashboard/Dashboard";
import Alert from "./components/layouts/Alert";
import Login from "./components/auth/Login";
import Airdrop from "./components/airdrop/Airdrop";
import TaskList from "./components/task/TaskList";
import AddTask from "./components/task/AddTask";
import MaskotList from "./components/maskot/MaskotList";
import SkinList from "./components/skin/SkinList";
import AddSkin from "./components/skin/AddSkin";
import AddMaskot from "./components/maskot/AddMaskot";
import MineList from "./components/Mine/MineList";
import DailyTask from "./components/daily/DailyTask";
import AddMine from "./components/Mine/AddMine";
import Top10Balance from "./components/details/Top10Balance";
import Top10Level from "./components/details/Top10Level";

// Redux
import store from "./redux/store";

// Utils
import setAuthToken from "./utils/setAuthToken";

// Types
import { loadUser } from "./redux/actions/auth";
import { LOGOUT } from "./redux/types";
import Detail from "./components/details/Detail";

const App = () => {
  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Router>
      <DefaultLayout>
        {/* <Navbar /> */}
        <Alert />
        <Routes>
          <Route
            index
            element={
              <>
                <PageTitle title="Admin Dashboard | Honey Bear Boot" />
                <Landing />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <PageTitle title="Admin Dashboard | Sign In" />
                <Login />
              </>
            }
          />
          <Route
            path="/dashboard"
            element={
              <>
                <PageTitle title="Admin Dashboard | Summary" />
                <Dashboard />
              </>
            }
          />
          <Route
            path="/airdrop"
            element={
              <>
                <PageTitle title="Admin Dashboard | Airdrop" />
                <Airdrop />
              </>
            }
          />
          <Route
            path="/tasks"
            element={
              <>
                <PageTitle title="Admin Dashboard | Tasks" />
                <TaskList />
              </>
            }
          />
          <Route
            path="/create-task"
            element={
              <>
                <PageTitle title="Admin Dashboard | Add Task" />
                <AddTask />
              </>
            }
          />
          <Route
            path="/maskots"
            element={
              <>
                <PageTitle title="Admin Dashboard | Maskots" />
                <MaskotList />
              </>
            }
          />
          <Route
            path="/create-maskot"
            element={
              <>
                <PageTitle title="Admin Dashboard | Add Maskot" />
                <AddMaskot />
              </>
            }
          />
          <Route
            path="/skins"
            element={
              <>
                <PageTitle title="Admin Dashboard | Skins" />
                <SkinList />
              </>
            }
          />
          <Route
            path="/create-skin"
            element={
              <>
                <PageTitle title="Admin Dashboard | Create Skin" />
                <AddSkin />
              </>
            }
          />
          <Route
            path="/mines"
            element={
              <>
                <PageTitle title="Admin Dashboard | Mines" />
                <MineList />
              </>
            }
          />
          <Route
            path="/create-mine"
            element={
              <>
                <PageTitle title="Admin Dashboard | Add Mine" />
                <AddMine />
              </>
            }
          />
          <Route
            path="/daily"
            element={
              <>
                <PageTitle title="Admin Dashboard | DailyTask" />
                <DailyTask />
              </>
            }
          />
          <Route
            path="/top-balances"
            element={
              <>
                <PageTitle title="Admin Dashboard | Top 10 Users (Balance)" />
                <Top10Balance />
              </>
            }
          />
          <Route
            path="/top-levels"
            element={
              <>
                <PageTitle title="Admin Dashboard | Top 10 Users (Level)" />
                <Top10Level />
              </>
            }
          />
          <Route
            path="/details"
            element={
              <>
                <PageTitle title="Admin Dashboard | Details" />
                <Detail />
              </>
            }
          />
        </Routes>
      </DefaultLayout>
    </Router>
  );
};

export default App;
