import api from "../../utils/api";
import { SKINS_FAIL, SKINS_LOADED, SKINS_SUCCESS } from "../types";
import { setAlert } from "./alert";

export const loadSkins = () => async (dispatch: any) => {
  try {
    const res = await api.get("/config/skins");

    dispatch({
      type: SKINS_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: SKINS_FAIL,
    });
  }
};

export const createSkin = (formData: FormData) => async (dispatch: any) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    await api.post("/config/skins", formData, config);

    dispatch({
      type: SKINS_SUCCESS,
    });

    dispatch(loadSkins());

    setAlert("Skin created", "success");

    return true;
  } catch (err: any) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error: any) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: SKINS_FAIL,
    });

    return false;
  }
};

export const deleteSkin = (id: string) => async (dispatch: any) => {
  try {
    await api.delete(`/config/skins/${id}`);

    dispatch({
      type: SKINS_SUCCESS,
    });

    dispatch(loadSkins());
    setAlert("Skin deleted", "success");
  } catch (error) {
    setAlert("Delete a skin failed", "danger");

    dispatch({
      type: SKINS_FAIL,
    });
  }
};
