import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import toast from "react-hot-toast";

const Alert = ({ alerts }: any) => (
  <div className="alert-wrapper">
    {alerts?.map((alert: any) => {
      const type =
        alert.alertType === "success"
          ? 0
          : alert.alertType === "warning"
          ? 1
          : 2;
      toast.custom((t) => (
        <div
          className={`${t.visible ? "animate-enter" : "animate-leave"}
      max-w-md w-full ${
        type == 0 ? "bg-[#04b20c]" : type == 1 ? "bg-[#eab90f]" : "bg-[#e13f32]"
      } shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
        >
          <div className="flex-1 w-0 p-4 ">
            <div className="flex items-start">
              <div className="ml-3 flex-1">
                <p className="text-sm font-medium text-white">{alert.id}</p>
                <p className="mt-1 text-sm text-white">{alert.msg}</p>
              </div>
            </div>
          </div>
          <div className="flex">
            <button
              onClick={() => toast.dismiss(t.id)}
              type="button"
              className="mr-2 box-content rounded-none border-none opacity-100 hover:no-underline hover:opacity-50 focus:opacity-50 focus:shadow-none focus:outline-none text-white"
              data-te-toast-dismiss
              aria-label="Close"
            >
              <span className="w-[1em] focus:opacity-100 disabled:pointer-events-none disabled:select-none disabled:opacity-25 [&.disabled]:pointer-events-none [&.disabled]:select-none [&.disabled]:opacity-25">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="h-6 w-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </button>
          </div>
        </div>
      ));
    })}
  </div>
);

Alert.propTypes = {
  alerts: PropTypes.array,
};

const mapStateToProps = (state: any) => ({
  alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
