// Load Utils
import api from "../../utils/api";

// Types
import {
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  USER_LOADED,
} from "../types";
import { setAlert } from "./alert";

// Load User
export const loadUser = () => async (dispatch: any) => {
  try {
    const res = await api.get("/config/auth");

    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Login User
export const login =
  (email: string, password: string, setToken: any, setIsLogin: any) =>
  async (dispatch: any) => {
    const body = { email, password };

    try {
      const res = await api.post("/config/auth", body);

      setToken(res.data);
      setIsLogin(true);
    } catch (err: any) {
      const errors = err.response.data.errors;

      if (errors) {
        errors.forEach((error: any) => dispatch(setAlert(error.msg, "danger")));
      }

      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

export const resend =
  (email: string, setIsResent: any) => async (dispatch: any) => {
    const body = { email };

    try {
      await api.post("/config/auth/resend", body);

      setIsResent(true);
      setTimeout(() => {
        setIsResent(false);
      }, 5000);
    } catch (error) {
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

export const verify =
  (code: string, email: string, token: any) => async (dispatch: any) => {
    const body = { code, email };

    try {
      const res = await api.post("/config/auth/verify", body);
      if (res.status === 200) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: token,
        });

        dispatch(loadUser());
      }
    } catch (error) {
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  };

// Logout
export const logout = () => ({ type: LOGOUT });
