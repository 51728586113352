import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Breadcrumb from "../common/BreadCrumb";
import flatpickr from "flatpickr";

import {
  createAirdropConfig,
  loadAirdropConfig,
  shareTokens,
} from "../../redux/actions/airdrop";

interface IAirdrop {
  config: IConfig;
  loading: boolean;
}

interface IConfig {
  endDate: string;
  prize: number;
  tokenPerFriend: number;
  tokenPerTask: number;
  tokenPerBalance: number;
}

const initialState = {
  endDate: "",
  amount: 0,
  ppf: 0,
  ppt: 0,
  ppb: 0,
};

const Airdrop: any = ({
  isAuthenticated,
  airdrop: { config, loading },
  createAirdropConfig,
  loadAirdropConfig,
  shareTokens,
}: {
  isAuthenticated: boolean;
  createAirdropConfig: any;
  shareTokens: any;
  loadAirdropConfig: any;
  airdrop: IAirdrop;
}) => {
  const [formData, setFormData] = useState(initialState);

  const navigate = useNavigate();

  const { endDate, amount, ppf, ppt, ppb } = formData;

  useEffect(() => {
    // Init flatpickr
    flatpickr(".form-datepicker", {
      mode: "single",
      onChange: function (selectedDates, dateStr, instance) {
        setFormData({ ...formData, ["endDate"]: String(selectedDates[0]) });
      },
      defaultDate: new Date(endDate),
      static: true,
      monthSelectorType: "static",
      enableTime: true,
      dateFormat: "Y-m-d H:i",
      altInput: true,
      prevArrow:
        '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
      nextArrow:
        '<svg className="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
    });
  }, [endDate]);

  useEffect(() => {
    if (!config) loadAirdropConfig();

    if (!loading && config) {
      const configData = { ...initialState };

      const formattedEndDate = new Date(config.endDate)
        .toISOString()
        .slice(0, 16);
      configData.endDate = formattedEndDate;
      configData.amount = config.prize;
      configData.ppf = config.tokenPerFriend;
      configData.ppt = config.tokenPerTask;
      configData.ppb = config.tokenPerBalance;

      setFormData(configData);
    }
  }, [loading, config, loadAirdropConfig]);

  const onChange = (e: any) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e: any) => {
    e.preventDefault();

    createAirdropConfig(endDate, amount, ppf, ppt, ppb).then((res: boolean) => {
      if (res) navigate("/dashboard");
    });
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Breadcrumb pageName="Airdrop Configuration" />
      <div>
        <div className="w-full p-4 sm:p-12.5 xl:p-10.5">
          <div className="flex flex-col gap-9">
            {/* <!-- Contact Form --> */}
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  Configuration Form
                </h3>
              </div>
              <form className="form" onSubmit={onSubmit} noValidate>
                <div className="p-6.5">
                  <div className="mb-4.5">
                    <label className="mb-3 block text-sm font-medium text-black dark:text-white">
                      Date (to sets up airdrop end date)
                    </label>
                    <div className="relative">
                      <input
                        type="datetime-local"
                        name="endDate"
                        onChange={onChange}
                        className="form-datepicker w-full rounded border-[1.5px] border-stroke bg-transparent px-5 py-3 font-normal outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        placeholder="mm/dd/yyyy"
                        data-class="flatpickr-right"
                      />

                      <div className="pointer-events-none absolute inset-0 left-auto right-5 flex items-center">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.7504 2.9812H14.2879V2.36245C14.2879 2.02495 14.0066 1.71558 13.641 1.71558C13.2754 1.71558 12.9941 1.99683 12.9941 2.36245V2.9812H4.97852V2.36245C4.97852 2.02495 4.69727 1.71558 4.33164 1.71558C3.96602 1.71558 3.68477 1.99683 3.68477 2.36245V2.9812H2.25039C1.29414 2.9812 0.478516 3.7687 0.478516 4.75308V14.5406C0.478516 15.4968 1.26602 16.3125 2.25039 16.3125H15.7504C16.7066 16.3125 17.5223 15.525 17.5223 14.5406V4.72495C17.5223 3.7687 16.7066 2.9812 15.7504 2.9812ZM1.77227 8.21245H4.16289V10.9968H1.77227V8.21245ZM5.42852 8.21245H8.38164V10.9968H5.42852V8.21245ZM8.38164 12.2625V15.0187H5.42852V12.2625H8.38164V12.2625ZM9.64727 12.2625H12.6004V15.0187H9.64727V12.2625ZM9.64727 10.9968V8.21245H12.6004V10.9968H9.64727ZM13.8379 8.21245H16.2285V10.9968H13.8379V8.21245ZM2.25039 4.24683H3.71289V4.83745C3.71289 5.17495 3.99414 5.48433 4.35977 5.48433C4.72539 5.48433 5.00664 5.20308 5.00664 4.83745V4.24683H13.0504V4.83745C13.0504 5.17495 13.3316 5.48433 13.6973 5.48433C14.0629 5.48433 14.3441 5.20308 14.3441 4.83745V4.24683H15.7504C16.0316 4.24683 16.2566 4.47183 16.2566 4.75308V6.94683H1.77227V4.75308C1.77227 4.47183 1.96914 4.24683 2.25039 4.24683ZM1.77227 14.5125V12.2343H4.16289V14.9906H2.25039C1.96914 15.0187 1.77227 14.7937 1.77227 14.5125ZM15.7504 15.0187H13.8379V12.2625H16.2285V14.5406C16.2566 14.7937 16.0316 15.0187 15.7504 15.0187Z"
                            fill="#64748B"
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Prize Amount
                    </label>
                    <input
                      type="number"
                      placeholder="Enter your prize amount"
                      min={0}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="amount"
                      value={amount}
                      onChange={onChange}
                    />
                    <p>
                      Prize amount to give users for airdrop; this value is used
                      when manual airdrop is activated.
                    </p>
                  </div>
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Price Per Friend
                    </label>
                    <input
                      type="number"
                      placeholder="Price Per Friend"
                      min={0}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="ppf"
                      value={ppf}
                      onChange={onChange}
                    />
                    <p>
                      Token amount per friend request. This amount is how much
                      you get when you invite a friend.
                    </p>
                  </div>
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Price Per Task
                    </label>
                    <input
                      type="number"
                      placeholder="Price Per Task"
                      min={0}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="ppt"
                      value={ppt}
                      onChange={onChange}
                    />
                    <p>
                      Token amount per task. This amount is how much you get
                      when you complete a task.
                    </p>
                  </div>
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Price Per Game Point
                    </label>
                    <input
                      type="number"
                      placeholder="Price Per Game Point"
                      min={0}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="ppb"
                      value={ppb}
                      onChange={onChange}
                    />
                    <p>
                      Token amount per task. This amount is how much you get
                      when you complete a task.
                    </p>
                  </div>
                  <input
                    type="submit"
                    className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
                    value="Submit"
                  />
                </div>
              </form>
              <button
                className="mb-4 w-[95%] flex m-auto justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  shareTokens();
                  navigate("/dashboard");
                }}
              >
                Start Airdrop
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Airdrop.propTypes = {
  createAirdropConfig: PropTypes.func.isRequired,
  loadAirdropConfig: PropTypes.func.isRequired,
  shareTokens: PropTypes.func.isRequired,
  airdrop: PropTypes.object.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  airdrop: state.airdrop,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  shareTokens,
  loadAirdropConfig,
  createAirdropConfig,
})(Airdrop);
