import api from "../../utils/api";
import { MINES_FAIL, MINES_LOADED, MINES_SUCCESS } from "../types";
import { setAlert } from "./alert";

export const loadMines = () => async (dispatch: any) => {
  try {
    const res = await api.get("/config/mines");

    dispatch({
      type: MINES_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: MINES_FAIL,
    });
  }
};

export const createMine = (formData: any) => async (dispatch: any) => {
  try {
    await api.post("/config/mines", formData);

    dispatch({
      type: MINES_SUCCESS,
    });

    dispatch(loadMines());

    setAlert("Mine created", "success");

    return true;
  } catch (err: any) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach((error: any) => dispatch(setAlert(error.msg, "danger")));
    }

    dispatch({
      type: MINES_FAIL,
    });

    return false;
  }
};

export const deleteMine = (id: string) => async (dispatch: any) => {
  try {
    await api.delete(`/config/mines/${id}`);

    dispatch({
      type: MINES_SUCCESS,
    });

    dispatch(loadMines());
    setAlert("Mine deleted", "success");
  } catch (error) {
    setAlert("Delete a Mine failed", "danger");

    dispatch({
      type: MINES_FAIL,
    });
  }
};
