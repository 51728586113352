import { TASKS_LOADED } from "../types";

const initialState = {
  loading: true,
  tasks: null,
};

function taskReducer(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case TASKS_LOADED:
      return {
        ...state,
        loading: false,
        tasks: payload,
      };
    default:
      return state;
  }
}

export default taskReducer;
