import React, { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { createMine } from "../../redux/actions/mine";
import Breadcrumb from "../common/BreadCrumb";

interface ICreateMine {
  createMine: any;
  isAuthenticated: boolean;
}

const AddMine: any = ({ createMine, isAuthenticated }: ICreateMine) => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    title: "",
    initial_price: 0,
    initial_profit: 0,
    times: 0,
  });

  const { title, initial_price, initial_profit, times } = formData;

  const onChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();

    createMine(formData).then(() => navigate("/mines"));
  };

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <Breadcrumb pageName="Create a Mine item" />

      <div>
        <div className="w-full p-4 sm:p-12.5 xl:p-10.5">
          <div className="flex flex-col gap-9">
            {/* <!-- Contact Form --> */}
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-6.5 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  Configuration Form
                </h3>
              </div>
              <form className="form" onSubmit={onSubmit}>
                <div className="p-6.5">
                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Title
                    </label>
                    <input
                      type="text"
                      placeholder="Enter the title"
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="title"
                      value={title}
                      onChange={onChange}
                    />
                    <p>The tile of the mine item.</p>
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Initial Price
                    </label>
                    <input
                      type="number"
                      placeholder="Enter the initial price"
                      min={0}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="initial_price"
                      value={initial_price}
                      onChange={onChange}
                    />
                    <p>Initial price to purchase mine item.</p>
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Initial Profit
                    </label>
                    <input
                      type="number"
                      placeholder="Enter the initial profit"
                      min={0}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="initial_profit"
                      value={initial_profit}
                      onChange={onChange}
                    />
                    <p>
                      Initial profit to earn hourly when user purchased this
                      mine item.
                    </p>
                  </div>

                  <div className="mb-4.5">
                    <label className="mb-2.5 block text-black dark:text-white">
                      Initial Profit
                    </label>
                    <input
                      type="number"
                      placeholder="Enter the times"
                      min={0}
                      className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 text-black outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:text-white dark:focus:border-primary"
                      name="times"
                      value={times}
                      onChange={onChange}
                    />
                    <p>
                      For example, let's assume this value is 2. Whenever user
                      upgrades this mine item, item's initial price and profit
                      is increased by 2 times. 2 * initial price (initial
                      profit)
                    </p>
                  </div>

                  <input
                    type="submit"
                    className="flex w-full justify-center rounded bg-primary p-3 font-medium text-gray hover:bg-opacity-90"
                    value="Submit"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AddMine.propTypes = {
  createMine: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { createMine })(AddMine);
